import React from "react";
import { AudioV2 } from "../../../_components/Audio";
import { capitalize, isJsonString } from "../../../_helpers/utils";

export default function ResultQuestionVocabulary({dataQuestion, indexQuestion, answer}) {
  const finalAnswer = isJsonString(answer) ? JSON.parse(answer) : answer;

  const _colorQ = (title) => {
    switch (title) {
        case 'fair': 
            return '#FF9900';
        case 'good':
            return '#f08b01';
        case 'excellent':
            return '#B9D546';
        default:
            return '#EE5555';
    }
};

  return (
    <div style={{width: '100%'}}>
      {dataQuestion?.map((item, index) => {
        const userChoice = finalAnswer?.find(ans => ans?.question_id == item?.id);
        const choiceQues1 = userChoice?.detail_step?.find(detail => detail?.step == 1);
        const choiceQues2 = userChoice?.detail_step?.find(detail => detail?.step == 2);
        const choiceQues3 = userChoice?.detail_step?.find(detail => detail?.step == 3);
        const choiceQues4 = userChoice?.detail_step?.find(detail => detail?.step == 4);
        const choiceQues5 = userChoice?.detail_step?.find(detail => detail?.step == 5);
        const choiceQues6 = userChoice?.detail_step?.find(detail => detail?.step == 6);
        const choiceQues7 = userChoice?.detail_step?.find(detail => detail?.step == 7);

        return (
          <div key={index} style={{
            flex: 1, 
            marginTop: 32,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#cdcdcd',
            borderRadius: 8,
            backgroundColor: '#faf7f7',
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 32,
          }}
          >
            <p className="vocab-title" style={{fontSize: 20, textAlign: 'center'}}>{capitalize(item?.vocabulary)}</p>
            <div>
              <p className="vocab-title">Question 1. Drag words over image</p>
              <div style={{alignItems: 'center'}}>
                <div style={{paddingVertical: 8}}>
                  <img 
                    src={isJsonString(item?.image) ? JSON.parse(item?.image)?.[0] : item?.image}
                    style={{
                      width: 150,
                      height: 150,
                    }}
                  />
                </div>
                {!!choiceQues1 && (
                  <div>
                    <p className="vocab-right-ans" style={{color: !choiceQues1?.score ? '#EE5555' : '#62B440'}}>{choiceQues1?.user_choice}</p>
                  </div>
                )}
                {!choiceQues1?.score && <div style={{display: 'flex', gap: 6}}>
                  <ImageHandRight />
                  <p className="vocab-right-ans">{isJsonString(item?.vi_mean) ? capitalize(JSON.parse(item?.vi_mean)?.[0]) : capitalize(item?.vi_mean)}</p>
                 </div> }  
              </div>
            </div>
            {/* Vocab 2 */}
            <div>
              <p className="vocab-title">Question 2. Click on the microphone and read the word.</p>
              <div>
                <div style={{display: 'flex', gap: 6}}>
                  {/* <ImageHandRight /> */}
                  <p className="vocab-right-ans">
                    {item?.vocabulary}
                  </p>
                </div>
                <div style={{display: 'flex', gap: 6}}>
                  <div 
                    // style={{width: 24, height: 24}} 
                  />
                  <p>{item?.spell}</p>
                </div>
                {!!choiceQues2 && (
                  <div style={{marginTop: 12, paddingRight: 200}}>
                    {/* {!!choiceQues2?.audio_recorder_path && <AudioV2
                      link={choiceQues2?.audio_recorder_path}
                    />} */}
                    <p className="vocab-right-ans" style={{
                      marginTop: 6,
                      color: _colorQ(choiceQues2?.review)
                    }}>{choiceQues2?.percent >= 0 ? (choiceQues2?.percent + '%') : choiceQues2?.review}</p>
                  </div>
                )}
              </div>
            </div>
            {/* Vocab 3 */}
            <div>
              <p className="vocab-title">Question 3. Write the English words for the picture.</p>
              <div>
                {!!choiceQues3 && (
                  <div>
                    <p className="vocab-right-ans" style={{color: !choiceQues3?.score ? '#EE5555' : '#62B440'}}>{choiceQues3?.user_choice}</p>
                  </div>
                )}
                {!choiceQues3?.score && <div style={{display: 'flex', gap: 6}}>
                  <ImageHandRight />
                  <p className="vocab-right-ans" style={{textTransform: 'uppercase'}}>
                    {item?.vocabulary}
                  </p>
                </div>}
              </div>
            </div>
            {/* Vocab 4 */}
            {/* <div>
              <Text className="vocab-title">Question 4. Translate the sentence into English.</Text>
              <div>
                <Text>{item?.vi_en_sentence}</Text>
                <div style={{display: 'flex', gap: 6}}>
                  <ImageHandRight />
                  <Text style={styles.textAns}>
                    {isJsonString(item?.vi_en_sentence_mean) ? JSON.parse(item?.vi_en_sentence_mean)?.[0] : item?.vi_en_sentence_mean}
                  </Text>
                </div>
              </div>
            </div> */}
            {/* Vocab 6 */}
            <div>
              <p className="vocab-title">Question 4. Click on the microphone and read the sentence.</p>
              <div>
                <p>
                  {isJsonString(item?.vi_en_sentence_mean) ? JSON.parse(item?.vi_en_sentence_mean)?.[0] : item?.vi_en_sentence_mean}
                </p>
                {/* <div style={{paddingRight: 200, paddingTop: 16}}>
                  <AudioV2
                    link={!!choiceQues6?.audio_recorder_path ? choiceQues6?.audio_recorder_path : item?.vi_en_sentence_audio}
                  />
                </div> */}
                {!!choiceQues6 && (
                  <div style={{marginTop: 4}}>
                    <p className="vocab-right-ans" style={{
                      color: _colorQ(choiceQues6?.review)
                    }}>{choiceQues6?.percent >= 0 ? (choiceQues6?.percent + '%') : choiceQues6?.review}</p>
                  </div>
                )}
              </div>
            </div>
            {/* Vocab 5 */}
            <div>
              <p className="vocab-title">Question 5. Listen and write what you hear.</p>
              <div>
                <div style={{paddingRight: 200, paddingTop: 16}}>
                  <AudioV2
                    link={item?.en_vi_sentence_audio}
                    />
                </div>
                <div style={{marginTop: 12}}>
                  {!!choiceQues5 && (
                    <div>
                      <p className="vocab-right-ans" style={{color: !choiceQues5?.score ? '#EE5555' : '#62B440'}}>{choiceQues5?.user_choice}</p>
                    </div>
                  )}
                  {!choiceQues5?.score && <div style={{display: 'flex', gap: 6}}>
                    <ImageHandRight />
                    <p className="vocab-right-ans">
                      {isJsonString(item?.en_vi_sentence) ? JSON.parse(item?.en_vi_sentence)?.[0] : item?.en_vi_sentence}
                    </p>
                  </div>}
                </div>
              </div>
            </div>
            {/* Vocab 7 */}
            <div style={{paddingBottom: 16}}>
              <p className="vocab-title">Question 6. Match the word with its suitable meaning.</p>
              <div>
              <div style={{display: 'flex', gap: 6}}>
                  <div style={{width: 24, height: 24}} />
                  <p>
                    {item?.vocabulary}
                  </p>
                </div>
                {!!choiceQues7 && (
                  <div>
                    <p className="vocab-right-ans" style={{color: !choiceQues7?.score ? '#EE5555' : '#62B440'}}>{choiceQues7?.user_choice}</p>
                  </div>
                )}
                {!choiceQues7?.score && <div style={{display: 'flex', gap: 6}}>
                  <ImageHandRight />
                  <p className="vocab-right-ans">
                    {isJsonString(item?.en_mean) ? JSON.parse(item?.en_mean)?.[0] : item?.en_mean}
                  </p>
                </div>}
              </div>
            </div>
          </div>
         
        )
      })}
    </div>
  )
}

const ImageHandRight = () => {
  return (
    <img 
      src="/assets/images/ico_hover_answer.png"
      style={{
        width: 24,
        height: 20,
      }}
    />
  )
}