import React, { useEffect, useState, useRef } from "react";
import { studentService } from "../../_services";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { Header } from "../../_components/Admin/Header";
import { PopUpYesNo } from "../../_components/Popup";
import { configConstants } from "../../_constants";
import DoTestExamModal from "./exam-test/DoTestExam";
import LessonBase from "../../_base/LessonBase";
import { hasDuplicates } from "../../_base/Validate";
import LazyLoad from "react-lazyload";
import { isEmpty } from "lodash";

function ViewExerciseStudent() {
  const [exersices, setExersices] = useState([]);

  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);
  const [showPopup, setShowPopup] = useState(false);
  const [status, setStatus] = useState("0");
  const { teacher_id, id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const [examSelected, selectExam] = useState(null);
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("page");

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadMore, setLoadMore] = useState(true);

  const listExerciseRef = useRef(null);

  const fetchDataExerciseFirstTime = () => {
    setLoading(true);
    studentService
      .getExercise(teacher_id, id, configConstants.DEFAULT_LIMIT, 0)
      .then((exersices) => {
        setExersices(exersices.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDataExerciseFirstTime();
  }, []);

  const handleVisibilityChange = async () => {
    if (document.visibilityState == "visible") {
      if (listExerciseRef.current) {
        listExerciseRef.current.scrollTop = 0;
      }
      setLoading(false);
      setLoadMore(true);
      setCurrentPage(0);
      fetchDataExercise(
        teacher_id,
        id,
        configConstants.DEFAULT_LIMIT,
        currentPage
      );
    }
  };

  useEffect(() => {
    window.addEventListener("pageshow", handleVisibilityChange);
    return () => {
      window.removeEventListener("pageshow", handleVisibilityChange);
    };
  }, []);

  const fetchDataExercise = (teacher_id, id, defaultLimit, currentPage) => {
    setLoading(true);
    studentService
      .getExercise(teacher_id, id, defaultLimit, currentPage)
      .then((exersices) => {
        setExersices(exersices.data);
        setLoading(false);
      });
  };

  const getListExercises = async (offset, isLoading) => {
    if ((currentPage != offset || offset == 0) && !isLoading) {
      setLoading(true);
      const res = await studentService.getExercise(
        teacher_id,
        id,
        configConstants.DEFAULT_LIMIT,
        offset
      );

      if (res?.status) {
        if (!hasDuplicates(exersices, res.data)) {
          setLoading(false);
          setExersices((prev) => [...prev, ...res?.data]);
          setLoadMore(res.data?.length == configConstants.DEFAULT_LIMIT);
          setCurrentPage(offset);
        }
      }
    }
  };

  const goToExercise = (data) => {
    // if (data.exercise_type === 'exam') {
    //   studentService.learn_exam(data.id, data.exercise_type, data.class_id).then((learns) => {
    //     if (learns.url) {
    //       window.open(learns.url, "_blank");
    //     }
    //   });
    // } else {
    //   studentService.getStudentLesson(data.exercise_id).then((response) => {
    //     window.open(response.url, "_blank");
    //   })
    // }

    let typeUri = "";
    if (
      [
        "grammar",
        "listening",
        "pronunciation",
        "reading",
        "speaking",
        "writing",
        "vocabulary",
      ].includes(data?.exercise_type || data?.lesson_type)
    ) {
      typeUri = "lesson";
    } else if (["project"].includes(data?.exercise_type || data?.lesson_type)) {
      typeUri = "project";
    } else if (
      ["mini_test", "exam"].includes(data?.exercise_type || data?.lesson_type)
    ) {
      typeUri = "exam";
    } else if (
      ["homework"].includes(data?.exercise_type || data?.lesson_type)
    ) {
      typeUri = "homework";
    } else {
      typeUri = "lesson";
    }
    const url = `${
      configConstants.EXCERCISE_URL
    }index.php/example/lesson_demo?type=${typeUri}&id=${
      data?.lesson_id || data?.exercise_id
    }&token=${authentication?.jwt_token}`;
    window.open(url);
  };

  const routeToDoHomeWork = (item) => {
    setStatus(item.status);
    if (["exam", "mini_test"].includes(item.exercise_type)) {
      selectExam(item);
    } else if (
      parseInt(item?.status) === -1 &&
      (item.exercise_type === "project" ||
        (item.exercise_type === "writing" && item.question_type === "7") ||
        (item.exercise_type === "speaking" && item.question_type === "3"))
    ) {
      setItem(item);
      // goToExercise(true)
      // console.log('item =====', item);
      LessonBase._moveLesson(
        LessonBase.Type.homework,
        authentication,
        item,
        null,
        8
      );
    } else if (
      (item.exercise_type === "project" ||
        (item.exercise_type === "writing" && item.question_type === "7") ||
        (item.exercise_type === "speaking" && item.question_type === "3")) &&
      parseInt(item?.status) !== -1
    ) {
      setItem(item);
      setShowPopup(true);
      // console.log('item ======', item);
    } else {
      LessonBase._moveLesson(
        LessonBase.Type.homework,
        authentication,
        item,
        null,
        8
      );
      // goToExercise(item)
    }
  };

  const handleGoToHomework = () => {
    setShowPopup(false);
    // console.log("=====handleGoToHomework 2", authentication, item)
    // console.log('item ==== ', item);
    LessonBase._moveLesson(
      LessonBase.Type.homework,
      authentication,
      item,
      null,
      8
    );
    //goToExercise(item)
  };

  const goToHistoryExer = () => {
    setShowPopup(false);
    history.push(
      `/${authentication.role}/curriculum/${item.class_id}/skill/${
        item.id
      }/lesson/${item.exercise_type}/${item.exercise_id}/exercise/${item.id}/${
        item.exercise_type
      }?from_screen=home_work_teacher_page&teacher_id=${teacher_id}&topic=${
        item.exercise_topic
      }${!isEmpty(fromPage) ? `&page=${fromPage}` : null}` +
        `&lesson_name=${
          !isEmpty(item?.exercise_name) ? item?.exercise_name : ""
        }`
    );
  };

  const goToStudyGuidePage = (e, id, item) => {
    e.stopPropagation();
    history.push(
      `/${authentication.role}/curriculum/study-guild/${id}?page=morepage`,
      {
        lesson_infor: item,
      }
    );
  };

  // Handle Scroll List Exercises

  const handleScrollExercises = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
      isLoadMore &&
      !isLoading
    ) {
      getListExercises(currentPage + configConstants.DEFAULT_LIMIT, false);
    }
  };

  return (
    <div className="sunE-right-container">
      <Header title="Bài tập" isBack />
      <div className="sunE-container-box filter flex-column">
        <div
          onScroll={handleScrollExercises}
          ref={listExerciseRef}
          className="sunE-student-class-homework scrollbar-custom flex-1"
        >
          {exersices.map((data, i) => {
            return (
              <div
                className="sunE-giaotrinh-item flex-m rel box-shadow pointer_cursor"
                key={i}
                onClick={() => routeToDoHomeWork(data)}
              >
                <div className="img">
                  <img
                    src={`/assets/images/teacher/giaobai/${data.exercise_type?.toLocaleLowerCase()}.png`}
                    alt="details"
                  />
                </div>
                <div className="content rel flex-1">
                  <h2 className="title">
                    {!!data?.level && (
                      <span className={data?.level}>
                        {data?.level === "normal" ? "medium" : data?.level}
                      </span>
                      )}{" "}
                    {data.topic}
                  </h2>
                  <h3 className="desc">{data.exercise_name}</h3>
                  <p>{`${data?.unit ? `Unit ${data.unit} - ` : ''}${data?.unit_name ? data?.unit_name : ''}`}</p>
                  <p>{data?.curriculum_name}</p>
                  <p>
                    {moment(data.start_time).format("DD/MM/YYYY")}
                    &nbsp;&nbsp;&nbsp;
                    <img
                      src="/assets/images/chambai/ico_right.png"
                      alt="ico_right"
                    />
                    &nbsp;&nbsp;&nbsp;{" "}
                    {moment(data.end_time).format("DD/MM/YYYY")}
                  </p>
                  {data?.has_guide && <h3
                    className="desc pointer_cursor"
                    onClick={(e) => goToStudyGuidePage(e, data.id, data)}
                  >
                    Hướng dẫn học tập
                  </h3>}
                </div>
                <div className="ico-checked">
                  {/* {(parseInt(data?.status) === 1 && data.score !== null) && (
                    <img
                      src="/assets/images/student/ico_success.png"
                      alt="ico_success"
                    />
                  )} */}
                  {parseInt(data?.status) !== -1 && (
                    // ((data?.exercise_type === 'project') ||
                    //     (data?.exercise_type === 'writing' && data?.question_type === '7') ||
                    //     (data?.exercise_type === 'speaking' && data?.question_type === '3')) &&
                    <img
                      src="/assets/images/student/ico_success.png"
                      alt="ico_success"
                    />
                  )}
                </div>
                {parseInt(data?.status) === 0 &&
                  data.score === null &&
                  (data?.exercise_type === "project" ||
                    (data?.exercise_type === "writing" &&
                      data?.question_type === "7") ||
                    (data?.exercise_type === "speaking" &&
                      data?.question_type === "3")) && (
                    <div className="progress danger">
                      <span>Chờ chấm</span>
                    </div>
                  )}

                {parseInt(data?.status) === 1 && data.score !== null && (
                  <div className="progress danger">
                    <span>Điểm: {data.score}</span>
                  </div>
                )}
              </div>
            );
          })}
          {!isLoading && exersices.length === 0 && (
            <LazyLoad className="__horizontal_center flex-just-center col-100 ">
              <img src="/assets/images/empty_confirm_excercise.png" alt="" />
              <div className="__text_empty">
                Bạn chưa được giáo viên giao bài tập nào
              </div>
            </LazyLoad>
          )}
        </div>
      </div>
      {examSelected && (
        <DoTestExamModal
          setExamSelected={selectExam}
          isShow={examSelected !== null}
          onClose={() => {
            selectExam(null);
            fetchDataExercise(
              teacher_id,
              id,
              configConstants.DEFAULT_LIMIT,
              currentPage
            );
          }}
          mockTestId={examSelected?.id || examSelected?.exercise_id}
          isHomeWork={1}
          classId={examSelected?.class_id}
          unitId={examSelected?.unit_id}
          type={examSelected.exercise_type}
        />
      )}
      {showPopup && (
        <PopUpYesNo
          onClickYes={() => {
            parseInt(status) !== 1 ? handleGoToHomework() : goToHistoryExer();
          }}
          onClickNo={() => setShowPopup(false)}
          labelNo={"Không"}
          message={
            parseInt(status) === 0
              ? "Bạn đã nộp bài trước đó, bạn có chắc chắn muốn nộp lại?"
              : parseInt(status) === 1 &&
                "Bạn đã nộp bài và giáo viên đã chấm. Bạn có muốn xem bài chữa không?"
          }
        />
      )}
    </div>
  );
}

export { ViewExerciseStudent };
