import React, { useEffect, useRef, useState } from "react";
import { isJsonString, validOnlyWord, validWord } from "../../../_helpers/utils";
import { CheckBoxCustom, renderStatusCheckBox } from "../../../_components/exam-test/CheckBoxCustom";
import { AudioCustom, AudioV2 } from "../../../_components/Audio";

export default function ResultQuestionChooseLetters(props) {
  const {dataQuestion, skill, question_type, answer} = props;

  let listData = [];
  let audio=null;
  if ((skill == 'pronunciation' && question_type == 3)) {
    const newAnswer = JSON.parse(answer);
    audio = dataQuestion[0]?.list_option[0]?.content_question ? JSON.parse(dataQuestion[0]?.list_option[0]?.content_question)?.content_question_audio : null;
    listData = dataQuestion[0]?.list_option?.map(item => {
      const userChoice = newAnswer?.find(ans => {
        return validWord(ans?.words) === validWord(item?.match_option_text?.[0])
      });
      return {
        content: item?.match_option_text?.[0] || '',
        isRightAns: item?.score ? Number(item.score) > 0 : false,
        explainQuestion:  item?.question_explain?.content_question_text || item?.option_explain || "",
        userChoice: userChoice && {
          score: userChoice?.score,
          user_choice:  userChoice?.user_choice
        }
      }
    })
  }

  if ((skill == 'listening' && question_type == 7)) {
    const newAnswer = JSON.parse(answer)?.map(item => ({
      ...item,
      user_choice: !!item?.user_choice && isJsonString(item?.user_choice) ?  JSON.parse(item?.user_choice) : item?.user_choice
    }))
    listData = dataQuestion[0]?.list_option?.map((item, index) => {
      const userChoice = newAnswer?.find((ans, iAns) => {
        // return validWord(ans?.user_choice) === validWord(item?.match_option_text?.[0])
        return index === iAns
      });
      return {
        content: item?.match_option_text?.[0] || '',
        isRightAns: item?.score ? Number(item.score) > 0 : false,
        explainQuestion:  item?.question_explain?.content_question_text || item?.option_explain || "",
        userChoice: userChoice && {
          score: userChoice?.score,
          user_choice: userChoice?.user_choice == 0 ? '' : userChoice?.user_choice == 1 ? item?.match_option_text?.[0] : userChoice?.user_choice
        }
      }
    })
  }

  if ((skill == 'pronunciation' && question_type == 7)) {
    const newAnswer = JSON.parse(answer)
    dataQuestion?.map((ques, index) => {
      const item = ques?.list_option?.[0];
      const listLetter = removePunctuation(item?.question_content || item?.content)?.split(' ')
      const listResult = item?.option_text?.split(',')?.map(item => item?.trim()) 
      const listQ = listLetter?.map(letter => {
        const parseUser_choice = isJsonString(newAnswer?.[index]?.user_choice) ?  JSON.parse(newAnswer?.[index]?.user_choice) : newAnswer?.[index]?.user_choice
        const convertUser_choice = typeof parseUser_choice === 'object' ? parseUser_choice : parseUser_choice?.split(',');
        const userChoice = convertUser_choice?.map(item => item?.trim()).find(ans => {
          return validOnlyWord(ans) === validOnlyWord(letter)
        });
        return {
          content: letter || '',
          isRightAns: !!item?.option_text?.split(', ')?.find(option => validOnlyWord(option) === validOnlyWord(letter)),
          explainQuestion: "",
          userChoice: userChoice && {
            score: !!listResult?.find(result => validOnlyWord(result) === validOnlyWord(userChoice)) ? 1 : 0,
            user_choice: validOnlyWord(userChoice)
          }
        }
      })
      listData.push(listQ)
    })
  }

  const renderItem = (item, index) => {
    return (
      <div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems:'center'
        }}>
          <div 
            style={{
              marginLeft: 16,
              marginRight: 8,
              width: 24,
              height: 20,
            }}
          >
            {item?.isRightAns && !Number(item?.userChoice?.score) ? <img 
              src="/assets/images/ico_hover_answer.png"
              style={{
                width: 24,
                height: 20,  
              }}
            /> : null}
          </div>
          <CheckBoxCustom
              status={renderStatusCheckBox(
              item?.userChoice?.score == 0 && !!item?.userChoice?.user_choice,
              item?.isRightAns,
              true
              )}
              label={item?.content}
          />
          <div style={{marginLeft: 6, flex: 1}}>
            {(skill == 'pronunciation' && question_type == 3) && item?.explainQuestion && <p>
              {item.explainQuestion}
            </p>}
          </div>
        </div>
        {(skill == 'listening' && question_type == 7) && item?.isRightAns && item?.explainQuestion && <p style={{
            paddingLeft: 60,
          }}>
            <p style={{
              fontWeight: 'bold',
          }}>Giải thích: {' '}</p>
            {item.explainQuestion}
          </p>}
      </div>
    )
  }

  return (
    <div>
      {audio && <div className="questions title-question title_question_width" style={{display: 'block', margin: '8px 0 32px'}}>
        <AudioV2 link={audio} />
      </div>
      }
      <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
        {listData?.map((item, index) => {
          if (skill == 'pronunciation' && question_type == 7) {
            const audioPro7 = dataQuestion[index]?.list_option[0]?.content_question ? JSON.parse(dataQuestion[index]?.list_option[0]?.content_question)?.content_question_audio : null;
            return (
              <div>
                <p style={{fontWeight: 700}}>{`Question ${index + 1}. `}</p>
                {audioPro7 && <div className="questions title-question title_question_width" style={{display: 'block', margin: '8px 0 32px'}}>
                  <AudioV2 link={audioPro7} />
                </div>}
                <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
                  {item?.map((subItem, subIndex) => {
                    return (
                      <div key={subIndex}>
                        {renderItem(subItem, subIndex)}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          }
          return (
            <div key={index}>
              {renderItem(item, index)}
            </div>
          )
        })}
      </div>
    </div>
   
  )
}

function removePunctuation(text) {
  if(!text) return '';
  const punctuation = /[\.,?!]/g;
  const newText = text.replace(punctuation, "");
  return newText;
}