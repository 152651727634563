import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { apiCaller } from "../../../../_helpers"
import { convertUserChoice, formatExamDataQuestions, formatLessonDataQuestion } from "../../../../_helpers/formatDataLesson"
import { isJsonString } from "../../../../_helpers/utils"

export const EntranceScoreLogic = () => {
  const [listEntranceScore, setListEntranceScore] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isShowReview, setIsShowReview] = useState(false);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [resultExam, setResultExam] = useState([]);
  const [headingExam, setHeadingExam] = useState('');
  const {id} = useParams()

  const getData = () => {
    setIsLoading(true)
    const url = '/api_class/entrance_test' + '?class_id=' + id
    apiCaller(url, 'get', {}, null, true).then((res) => {
      setListEntranceScore(res?.data || [])
    }).catch(err => {
      alert(err)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleReviewMockTest = (item) => {
    const url = '/api_class/detail_mock_test_user?student_id=' + item?.student_id;
    apiCaller(url, 'get').then(res=>{
        if (res?.status) {
          const convertDataQuestion = formatExamDataQuestions(res);
          const resultAnswer = res?.list_question?.map(item => {
            const userChoice = isJsonString(item?.user_choice) ? JSON.parse(item?.user_choice) : item?.user_choice;
            return {
              questionId: item?.question_id,
              answer: userChoice?.[userChoice?.length - 1]?.user_choice
            }
          })
          setHeadingExam(item?.student_name)
          setDataQuestion(convertDataQuestion)
          setResultExam(resultAnswer)
          setIsShowReview(true)
        }
    }).catch(err=>{
      alert(err)
    })
  }

  const handleCloseReviewExam = () => {
    setIsShowReview(false);
    setHeadingExam('');
    setDataQuestion([]);
    setResultExam([]);
  }

  useEffect(() => {
    getData()
  }, [])

  return {
    listEntranceScore,
    isLoading,
    handleReviewMockTest,
    isShowReview,
    dataQuestion,
    resultExam,
    headingExam,
    handleCloseReviewExam
  }
}