import React from "react";
import { useHistory } from "react-router-dom";

function Header(props) {
  const title = props.title;
  const component = props.component ? props.component : null;
  const isBack = props.isBack ? true : false;
  const history = useHistory();
  const clickBack = props.clickBack
    ? () => props.clickBack()
    : () => history.goBack();

  return (
    <div className="sunE-main-title flex-m">
      <span className="line-h" />
      <h1 className="flex-1 one-line font_myriad_bold" style={props?.headingStyle}>{title}</h1>
      {component}
      {isBack ? (
        <div className="form-sunE-button">
          <button className="btn-line-blue" onClick={clickBack}>
            Quay lại
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export { Header };
