import React, {useState, useEffect} from 'react';
import './styles.resultLesson.scss';
import { isJsonString, toListDownLine, validWord } from '../../../_helpers/utils';
import { CheckBoxCustom, renderStatusCheckBox } from '../../../_components/exam-test/CheckBoxCustom';
import { AudioCustom, AudioV2 } from '../../../_components/Audio';

// con show dap an
const ResultQuestionMultipleChoice = (props) => {
    const {dataQuestion, answer, skill, question_type, infoQuestion} = props;
    const finalAnswer = isJsonString(answer) ? JSON.parse(answer) : answer;

    const isShowExplainOption = (skill == 'pronunciation' && question_type == 2) ||
                        (skill == 'pronunciation' && question_type == 10);
                        
    const renderOption = (item, index, iQues) => {
        let isOptionTrue = parseInt(item?.score) != 0;
        let isAnswerTrue = !!item.match_option_text?.find(text => validWord(finalAnswer?.[iQues]?.user_choice) == validWord(text));
        const explainQuestion =  item?.question_explain?.content_question_text
                                || item?.option_explain
                                || "";
        const textConvert = _convertText(item?.match_option_text?.[0]);
        return (
            <>
                <div style={{paddingLeft: 40, position: 'relative'}}>
                    {isOptionTrue && !isAnswerTrue && (
                      <div style={{position: 'absolute', left: 10, top: 0, width: 24}}>
                          <img
                            style={{ height: 20 }}
                            src="/assets/images/ico_hover_answer.png"
                          />
                      </div>
                    )}
                    <div style={{display: 'flex'}}>
                        <CheckBoxCustom
                            status={renderStatusCheckBox(
                            isAnswerTrue,
                            isOptionTrue,
                            true
                            )}
                            label={''}
                        />
                        {textConvert.str ? <p>{textConvert.str}</p> :(
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <span>{textConvert.str1}</span>
                                <span style={{textDecoration: 'underline'}}>{textConvert.str2}</span>
                                <span>{textConvert.str3}</span>
                            </div>
                        )}
                    </div>
                </div>
                {
                    isShowExplainOption && !!explainQuestion && <div
                    style={{marginLeft: 50 }}
                    >
                        <p style={{fontWeight: 700}}>Giải thích:</p>
                        <p style={{whiteSpace: 'pre-line'}}>{explainQuestion}</p>
                    </div>
                }
            </>
        );
    };

    const _convertText = (myString) => {
        let itemM = myString.match(/\[(.*?)\]/);
        let indices = {};
        if (itemM) {
            let indexOf = myString.indexOf(itemM[0]);
            let str1 = myString.slice(0, indexOf);
            let str2 = itemM[1];
            let str3 = myString.slice(indexOf + itemM[0].length, myString.length);
            indices['str1'] = str1;
            indices['str2'] = str2;
            indices['str3'] = str3;
        } else {
            indices['str'] = myString;
        }
        return indices;
    };

    const renderUnderLine=(s)=>{
        var matchs = s.match(/\[(.*?)\]/g);
        matchs.forEach(e=>{
            s = s.replace(e,' ____ ')
        })
        let c = 0;
        var bodyT = toListDownLine(s)
        return(
    <div style={{display: 'flex',flexDirection:'column'}}>
        {
            bodyT.map((mono, mKey) => {
                return(
                    <div style={{display: 'flex',flexDirection:'row',flexWrap:'wrap'}}>
                    { mKey == 0 && <div style={{
                                // height: SmartScreenBase.smPercenWidth*6,
                                justifyContent:'center',
                                // marginTop:SmartScreenBase.smPercenWidth
                            }}>
                                <span>{beginNum + 1}. </span>
                            </div>}
                    {
                        mono.split(' ').map((e,k)=>{
                            return <div style={{
                                borderBottomWidth:e==='____'?1:0,
                                // height: SmartScreenBase.smPercenWidth*6,
                                justifyContent:'center',
                                // marginTop:SmartScreenBase.smPercenWidth,
                                marginRight:2
                            }}>
                                {
                                    e==='____'?<span key={mKey+"_"+k} >{matchs[c++].replace(/[\[\]]/g,'')}</span>:
                                        <span key={mKey+"-"+k}>{e}</span>
                                }
                            </div>
                        })
                    }
                </div>
                )
            })
        }
    </div>
)}

    const formatScript=(s, index)=>{
      if(!s)return '';
      let idx = 1;
      return s.replace(/{.+?}/g,function(a,v){
          return `______(${index ? (index + 1) : idx++})`
      })
    }

    const paragraph = infoQuestion?.lesson_paragraph || infoQuestion?.paragraph;
console.log('numberReview: ', props?.numberReview)
    return (
        <div style={{flex: 1,marginTop:10}}>
            {paragraph ? <p style={{
                    paddingBottom: 16
                }}>
                    {formatScript(paragraph?.trim())}
                </p> : null
            }
            {dataQuestion?.map((item, index) => {
                const explainQuestion =  item?.list_option[0]?.question_explain?.content_question_text
                || item?.list_option[0]?.option_explain
                || "";
                const audio = item?.list_option[0]?.content_question ? JSON.parse(item?.list_option[0]?.content_question)?.content_question_audio : null;
            return (
                <div style={{marginTop: 24}}>
                    {
                        item?.list_option[0].question_content.indexOf('[')>=0
                        &&item?.list_option[0].question_content.indexOf(']')>=0?
                        renderUnderLine(item?.list_option[0].question_content)
                        :
                        <p style={{
                            color: 'black',
                        }}>
                            {`${props?.numberReview ? props?.numberReview : (index + 1)}. `} 
                            {item?.list_option[0].question_content}
                        </p>
                    }
                    {audio && <div className="questions title-question title_question_width" style={{display: 'block', margin: '8px 0 12px'}}>
                        <AudioV2 link={audio} />
                    </div>}
                    <div>
                        {
                            item?.list_option.map((e,i)=>{
                                return renderOption(e, i, index)
                            })
                        }
                    </div>
                    {
                    !isShowExplainOption && !!explainQuestion && <div
                    >
                        <p style={{fontWeight: 600}}>Giải thích:</p>
                        <p style={{whiteSpace: 'pre-line'}}>{explainQuestion}</p>
                    </div>
                    }
                </div>
            )})}
        </div>
    );
};

export default ResultQuestionMultipleChoice;
