import React, {useState, useEffect} from 'react';

const ResultRewriteWithTheGivenWords = (props) => {
    const {dataQuestion, type, answer} = props;
    const [userChose,setUserChose] = useState([]);

    useEffect(() => {
      setUserChose(JSON.parse(answer))
    }, [dataQuestion]);

    const _renderResult = (item, index) => {
        const chose = userChose[index];

        return (
            <div style={{marginLeft: 16}}>
                {
                    !!chose?.user_choice ?
                    <span>
                      {item?.hint}{' '}
                      <span style={{
                        color: !Number(chose?.score) ? 'red' : '#62B440',
                        }}
                      >
                        {chose?.user_choice}
                      </span>
                    </span>
                        : null

                }
                {
                    !Number(chose?.score) &&
                    <div style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex',
                        gap: 6
                    }}>
                        <img
                          style={{ height: 20 }}
                          src="/assets/images/ico_hover_answer.png"
                        />
                        <span>
                          {item?.hint}{' '}
                          <span style={{
                            color: '#62B440',
                            }}
                          >
                            {item?.match_option_text[0]}
                          </span>
                        </span>
                    </div>
                }
            </div>
        )
    };

    return (
      <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
        {
          dataQuestion.map((item, index) => {
            const explainQuestion = item?.list_option[0]?.question_explain?.content_question_text
                                      || item?.list_option[0]?.option_explain || "";
            return (
                <div key={index}>
                    <p>{props?.numberReview ? props?.numberReview : (index + 1)}. {item?.list_option?.[0]?.question_content}</p>
                    {
                      _renderResult(item?.list_option?.[0], index)
                    }
                    {!!explainQuestion && <div style={{marginTop: 8}}>
                      <p style={{fontWeight: 600}}>Giải thích:</p>
                      <p>{explainQuestion}</p>
                    </div>
                    }
                </div>
            );
          })
        }
      </div>
    );
};

export default ResultRewriteWithTheGivenWords;
