import { useParams } from "react-router-dom";
import { Header } from "../../../../_components/Admin/Header";
import { apiCaller, history } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import './DetailAssignedHomeWorkExercise.style.scss'
import { ReviewExam } from "../../../StudentPage/exam-test/ReviewExam";
import { convertUserChoice, formatExamDataQuestions, formatLessonDataQuestion } from "../../../../_helpers/formatDataLesson";
import { isJsonString, validWord } from "../../../../_helpers/utils";

export default function DetailAssignedHomeWorkExercise() {
  const authentication = useSelector((state) => state.authentication);
  const { id, turn_id, lesson_id, unit_id } = useParams();
  const heading = new URLSearchParams(window.location.search).get("heading");
  const skill = new URLSearchParams(window.location.search).get("skill")?.toLowerCase();
  const startTime = new URLSearchParams(window.location.search).get("startTime");
  const endTime = new URLSearchParams(window.location.search).get("endTime");
  const [listData, setListData] = useState([])
  const [totalStudent, setTotalStudent] = useState(0);
  const [isHasStudentDone, setIsHasStudentDone] = useState(false);
  const [isHideRatio, setIsHideRatio] = useState(false);
  const [isShowScore, setIsShowScore] = useState(false);
  const [isShowPercent, setIsShowPercent] = useState(false);
  const [isShowByWords, setIsShowByWords] = useState(false);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [skillQues, setSkillQues] = useState(0);
  const [questionType, setQuestionType] = useState('');
  const [isGetScoreByDetailTurn, setIsGetScoreByDetailTurn] = useState(false)
  const [data, setData] = useState();
  const [isShowReview, setIsShowReview] = useState(false);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [infoQuestion, setInfoQuestion] = useState();
  const [resultLesson, setResultLesson] = useState([]);
  const [headingReview, setHeadingReview] = useState('');
  const [numberReview, setNumberReview] = useState(0);
  const [listOptionChoose, setListOptionChoose] = useState([])

  const getData = async () => {
    const url = '/api_exercise_report/turn_lesson_report_details' + '?turn_id=' + turn_id + '&lesson_id=' + lesson_id + '&unit_id=' + unit_id
    try {
      const res = await apiCaller(url, 'get')
      if(res?.status) {
        const infoQuestion = res?.data_question?.[0]?.list_option?.[0] || res?.data_question?.list_option?.[0];
        const skillQuestion = skill || infoQuestion?.type || res?.data?.find(item => !!item?.user_choice?.length)?.user_choice?.[0]?.exercise_type;
        if (skillQuestion == 'speaking' && infoQuestion?.question_type == 2 ||
          skillQuestion == 'speaking' && infoQuestion?.question_type == 3 ||
          skillQuestion == 'pronunciation' && infoQuestion?.question_type == 1 ||
          // skillQuestion == 'pronunciation' && infoQuestion?.question_type == 3 ||
          skillQuestion == 'writing' && infoQuestion?.question_type == 7 ||
          skillQuestion == 'project' ||
          skillQuestion == 'vocabulary'
        ) {
          setIsHideRatio(true);
        }
        if (skillQuestion == 'writing' && infoQuestion?.question_type == 7 ||
          skillQuestion == 'speaking' && infoQuestion?.question_type == 3 ||
          skillQuestion == 'project' ||
          skillQuestion == 'vocabulary'
        ) {
          setIsShowScore(true)
        }
        if (skillQuestion == 'pronunciation' && infoQuestion?.question_type == 1) {
          setIsShowPercent(true)
        }
        if (skillQuestion == 'speaking' && infoQuestion?.question_type == 2) {
          setIsGetScoreByDetailTurn(true)
        }
        if (skillQuestion == 'pronunciation' && infoQuestion?.question_type == 3) {
          const listOption = res?.data_question?.list_option || res?.data_question?.[0]?.list_option;
          const listWords = listOption?.map(item => item?.match_option_text?.[0])
          const listTotalStudentRight = Array(listOption?.length).fill(0);
          const data = res?.data?.map(item => {
            const userChoice = transformUserChoice(res?.data_question, item?.user_choice)
            userChoice?.map((choice, index) => {
              if(choice?.score == 1 || (choice?.score == 0 && choice?.user_choice == 0)) {
                listTotalStudentRight[index] += 1
              }
            })
            return {
              ...item,
              userChoice
            }
          })
          setIsShowByWords(true)
          setTotalQuestion(listOption?.length || 0)
          setIsHasStudentDone(listTotalStudentRight.some(item => item > 0))
          setListData([{listNumberQuestion: listWords}, {listTotalStudentRight}, ...data])
        } else {
          const listNumberQuestion = Array(res?.total_question).fill(0).map((_, index) => index + 1);
          const listTotalStudentRight = Array(res?.total_question).fill(0);
          res?.data?.map(item => {
              item?.lesson_data?.map((lesson, index) => {
                if(lesson?.do_right) {
                  listTotalStudentRight[index] += 1
                }
              })
          })
          setTotalQuestion(res?.total_question)
          setIsHasStudentDone(listTotalStudentRight.some(item => item > 0))
          setListData([{listNumberQuestion}, {listTotalStudentRight}, ...res?.data])
        }
        setSkillQues(skillQuestion)
        setQuestionType(infoQuestion?.question_type)
        setTotalStudent(res?.data?.filter(item => !!item?.user_choice?.length)?.length)
        setData(res)
      }
    } catch (error) { 
    }
  };

  const transformUserChoice = (data_question, listUserChoice) => {
    const userChoice = listUserChoice?.map(item => {
      let user_choice = '[]';
      // const isGetDetailUserTurn = (item?.exercise_type == 'pronunciation' && item?.question_type == 8)
      if (
        data_question?.length === 1 || !!data_question?.question_id
        //  && !isGetDetailUserTurn
      ) {
        let finalUserChoice = [];
        if(item?.detail_user_turn_style_2?.find(item => !!item?.data?.length)) {
          item?.detail_user_turn_style_2?.map(choice => {
            if(choice?.num_turn > finalUserChoice[finalUserChoice.length - 1]?.num_turn) {
              finalUserChoice = [];
            }
            finalUserChoice = choice?.data;
          })
        } else {
          item?.detail_user_turn_style_2?.map(item => {
            if(item?.num_turn > finalUserChoice[finalUserChoice.length - 1]?.num_turn) {
              finalUserChoice = []
            }
            if (finalUserChoice.find(ans => JSON.stringify(ans) == JSON.stringify(item))) {
              return;
            }
            finalUserChoice.push(item)
          })
        }
        user_choice = finalUserChoice
      } else {
        let finalUserChoice = [];
          item?.detail_user_turn?.map(choice => {
            if(choice?.num_turn > finalUserChoice[finalUserChoice.length - 1]?.num_turn) {
              finalUserChoice = [];
            }
            finalUserChoice.push(choice);
          })
        user_choice = finalUserChoice
      }
      return {
        user_choice,
      }
    })
    return userChoice?.[0]?.user_choice?.flat();
  }

  const handleViewDetail = (item, iLesson) => {
    return;
    const data_question = !!data?.data_question?.length 
      ?  data?.data_question?.filter((_, iQues) => iQues == iLesson)
      : [data?.data_question]?.map(item => {
        return {
          ...item,
          list_option: item?.list_option?.filter((_, iOption) => iOption == iLesson)
        }
      });
    const dataQuestionFull = !!data?.data_question?.length ? data?.data_question : [data?.data_question]
    const isGetTurnByWord = skillQues == 'reading' && questionType == 7;
    const user_choice = !!data?.data_question?.length 
      ?  item?.user_choice?.filter((_, iChoice) => iChoice == iLesson)
      : item?.user_choice?.map(item => {
        return {
          ...item,
          detail_user_turn_style_2: item?.detail_user_turn_style_2?.map(detailTurn => {
            let data = detailTurn?.data?.filter((_, iData) => iData == iLesson);
            // if (isGetTurnByWord) {
            //   data = detailTurn?.data?.filter((turn, iData) => validWord(turn?.word) == validWord(dataQuestion?.[0]?.list_option?.[0]?.option_text));
            // }
            return {
              ...detailTurn,
              data
            }
          })
        }
      })
    const tempData = {
      data_question
    }
    const userChoice = convertUserChoice(dataQuestionFull, user_choice, skillQues);
    const convertDataQuestion = formatLessonDataQuestion(tempData, skill);
    const listOption = []
    console.log('dataQuestionFull: ', dataQuestionFull)
    if (skillQues == 'reading' && questionType == 8 ||
      skillQues == 'reading' && questionType == 9
    ) {
      dataQuestionFull?.map(data => {
        data?.list_option?.map((option) => {
          if(!listOption?.find(op => option?.match_option_text?.includes(op))) {
            listOption.push(option?.match_option_text?.[0])
          }
          if(!listOption?.find(op => option?.jamming_answer_parse?.includes(op))) {
            listOption.push(option?.jamming_answer_parse?.[0])
          }
        })
      })
    }
    setHeadingReview(item?.name)
    setNumberReview(iLesson + 1)
    setDataQuestion(convertDataQuestion)
    setResultLesson(userChoice)
    setListOptionChoose(listOption)
    setIsShowReview(true)
  }

  const renderItem = ({item, index}) => {
    const isDone = index > 1 && (isShowByWords ? !!item?.userChoice?.length : !!item?.lesson_data?.length);
    const countNumberRight = index > 1 ? isShowByWords ? item?.userChoice?.filter(choice => choice?.score == 1 || (choice?.score == 0 && choice?.user_choice == 0))?.length : item?.lesson_data?.filter(lesson => lesson?.do_right)?.length : 0

    return (
      <div className="turn_lesson_report_detail-item">
        <div 
          className="turn_lesson_report_detail-name" 
          style={{
            color: index === 1 ? '#00BEB4' : '#4D4D4D',
            fontWeight: index === 1 ? 'bold' : 'normal',
          }}
        >
          {index === 0 ? '' : index === 1 ? (isHideRatio ? '' : 'Tỷ lệ làm đúng') : item?.name}
        </div>
        <div className="turn_lesson_report_detail-box" style={{borderWidth: index > 1 && !isHideRatio ? 1 : 0, height: index === 1 && isHideRatio ? 'unset' : 28, width: isShowByWords ? 80 : 60, minWidth: isShowByWords ? 80 : 60, marginLeft: index === 0 && skillQues === 'project' ? 70 : 0}}>
          <span className="font-weight-bold">{index === 0 ? isShowByWords ? 'Từ' : (skillQues === 'project' ? 'Điểm' : 'Câu') : (index === 1 || isHideRatio) ? '' : isDone ? `${countNumberRight}/${totalQuestion}` : '___'}</span>
        </div>
        {index === 0 && item?.listNumberQuestion?.map(numberQuestion => (
          <div
            key={numberQuestion}
            className="turn_lesson_report_detail-box"
            style={{borderWidth: 1, width: isShowByWords ? 80 : 60, minWidth: isShowByWords ? 80 : 60}}
          >
            <span style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '100%',
              textAlign: 'center'
            }}>{numberQuestion}</span>
          </div>
        ))}
        {index === 1 && !isHideRatio && item?.listTotalStudentRight?.map((numberRight, iNumRight) => (
          <div
            key={iNumRight}
            className="turn_lesson_report_detail-box"
            style={{borderWidth: 1, borderColor: '#00BEB4', width: isShowByWords ? 80 : 60, minWidth: isShowByWords ? 80 : 60}}
          >
            <span className="font-weight-bold" style={{color: '#00BEB4'}}>{isHasStudentDone ? `${numberRight}/${totalStudent}` : '___'}</span>
          </div>
        ))}
        {index > 1 && !isShowByWords && 
          <>
            {!!item?.lesson_data?.length && !isShowPercent && !isShowScore ? item?.lesson_data?.map((lesson, iLesson) => (
              <div
                key={lesson?.id}
                className="turn_lesson_report_detail-box"
                style={{cursor: 'pointer'}}
                onClick={() => handleViewDetail(item, iLesson)}
              >
                <img 
                  className="turn_lesson_report_detail-box" 
                  src={lesson?.do_right ? '/assets/images/icon/icon_tick_rectangle.png' : '/assets/images/icon/icon_swrong_rectangle.png'} 
                  style={{objectFit: 'cover'}}/>
              </div>
            )) : (
              <>
                {!!item?.user_choice?.length ? item?.user_choice?.map((choice, iChoice) => {
                  const score = isGetScoreByDetailTurn ? choice?.detail_user_turn?.[choice?.detail_user_turn?.length - 1]?.score : choice?.question_score;
                  return (
                    <div
                      key={choice?.question_id}
                      className="turn_lesson_report_detail-box" 
                      style={{borderWidth: 1}}
                    >
                      <span className="font-weight-bold" style={{ fontSize: item?.status == 0 ? '12px' : '16px'}}>
                        {isShowScore ? 
                        item?.status == 0 ? 
                        "Chờ chấm" : 
                        item?.status == -1 ? 
                        '___' : 
                        (skillQues === 'vocabulary' ? 
                        Number(item?.lesson_data?.[iChoice]?.percent) / 10 : 
                        (Math.round((score * 10 || 0)) / 10)) : 
                        ((Math.round((score * 100 || 0) * 10) / 10) + '%' )}
                      </span>
                    </div>
                  )}
                ) : (
                  Array(totalQuestion).fill(0).map((_, i) => (
                    <div
                      key={i}
                      className="turn_lesson_report_detail-box" 
                      style={{borderWidth: 1}}
                    >
                      <span className="font-weight-bold">___</span>
                    </div>
                  ))
                )}
              </>
            )}
          </>
        }
        {index > 1 && isShowByWords && (
          <>
            {!!item?.userChoice?.length ? (
              item?.userChoice?.map((choice, iChoice) => (
                <div
                  key={iChoice}
                  className="turn_lesson_report_detail-box"
                  style={{width: 80, minWidth: 80}}
                >
                  <img 
                    className="turn_lesson_report_detail-box" 
                    src={choice?.score == 1 || (choice?.score == 0 && choice?.user_choice == 0) ? '/assets/images/icon/icon_tick_rectangle.png' : '/assets/images/icon/icon_swrong_rectangle.png'} 
                    style={{objectFit: 'cover', width: 80, minWidth: 80}}/>
                </div>
            ))) : (
              Array(totalQuestion).fill(0).map((_, i) => (
                <div
                  key={i}
                  className="turn_lesson_report_detail-box" 
                  style={{width: 80, minWidth: 80, borderWidth: 1}}
                >
                  <span className="font-weight-bold">___</span>
                </div>
              ))
            )}
          </>
        )}
      </div>
    )
  }

  useEffect(() => {
    getData()
  }, [])
  
  return (
    <div className="sunE-right-container rel detail-assigned-home-work-page">
      <Header
        title={heading}
        headingStyle={{
          fontSize: '26px',
          whiteSpace: 'normal'
        }}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id + "/exercise/detail-assigned/" + turn_id + '?startTime=' + startTime + '&endTime=' + endTime + '&indexTab=1' )
        }
      />
      <div className="sunE-content sunE-class-content">
        <div className="turn_lesson_report_detail-container scrollbar-custom">
          <div className="turn_lesson_report_detail-list">
            {listData?.map((item, index) => (
              <Fragment key={index}>
                {renderItem({item, index})}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <ReviewExam
        heading={headingReview}
        examInfo={infoQuestion}
        isShow={isShowReview}
        onClose={() => {
          setIsShowReview(false);
          setHeadingReview('')
          setDataQuestion([])
          setResultLesson([])
        }}
        listQuestions={dataQuestion}
        isShowResultLesson={true}
        resultLesson={resultLesson}
        numberReview={numberReview}
        listOptionChoose={listOptionChoose}
      />
    </div>
  )
}